<template>
  <v-container fluid>
    <PageHeader>
      <span slot="courses" class="font-weight-light">ASSIGN </span> PERMISSIONS
    </PageHeader>

    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-form ref="permissionForm" @submit.prevent>
              <v-row justify="center">
                <v-col cols="12" sm="5" md="5" lg="5">
                  <v-text-field
                    outlined
                    label="Permission Name"
                    v-model="permissionName"
                    :rules="[(v) => !!v || 'Permission Name is required']"
                    clearable
                    @keyup.enter="savePermissionBtn"
                    :loading="loading"
                    loader-height="2"
                    :error-messages="error_msgs"
                  ></v-text-field>
                </v-col>

                <v-col class="text-center" cols="12" sm="2" md="2" lg="2">
                  <v-btn
                    x-large
                    :disabled="clickOnce"
                    @click="savePermissionBtn"
                    class="mx-2"
                    fab
                    dark
                    color="#226597"
                  >
                    <v-icon x-large dark> mdi-plus </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row grow>
      <v-col>
        <v-form ref="editPermissionForm">
          <v-data-table
            fixed-header
            disable-pagination
            :headers="headers"
            :items="items"
            hide-default-footer
            class="elevation-2"
            item-key="id"
            :loading="isLoaded"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-edit-dialog single-line>
                <v-btn dark right large icon>
                  <v-icon dark class="red--text"> mdi-close-circle </v-icon>
                </v-btn>

                <template v-slot:input>
                  <v-card flat>
                    <v-card-text class="pa-3 ma-0">
                      Do You Want to Delete <b>{{ item.title }}</b> ?
                    </v-card-text>
                    <v-card-actions class="px-0 ma-0">
                      <v-btn
                        color="error"
                        block
                        @click="removePermissionBtn(item.id, item)"
                      >
                        Delete
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:no-data>
              <span class="subheading font-weight-bold">
                No Permission(s) available
              </span>
            </template>
          </v-data-table>
        </v-form>

        <v-card v-if="getters_scroll_paginate.length" v-intersect="intersected">
          <v-progress-linear
            :indeterminate="true"
            v-if="loadingScroll"
          ></v-progress-linear>
        </v-card>
      </v-col>
    </v-row>

    <Response style="z-index: 999" v-if="updateResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>

<script>
  import { getCurrentInstance, provide, reactive, toRefs, watch } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import Response from "@/components/ActionResponse/Response";
  import PageHeader from "@/components/slots/PageHeader";

  export default {
    components: { Response, PageHeader },
    setup() {
      const vm = getCurrentInstance()
      const { savePermission, getPermission, removePermission, signOut } =
        useActions([
          "savePermission",
          "getPermission",
          "removePermission",
          "signOut",
        ]);

      const { getters_scroll_paginate, getters_permission_meta } = useGetters([
        "getters_scroll_paginate",
        "getters_permission_meta",
      ]);

      const permissionForm = reactive({
        permissionName: null,
        loading: false,
        basket: {},
        clickOnce: false,
        error_msgs: "",
        pagination: [],
      });

      const editPermissionForm = reactive({
        adminResponse: false,
        isLoaded: true,
        adminPassword: "",
        checkAdmin: "",
        headers: [
          {
            text: "PERMISSION",
            value: "title",
          },
          { text: "ACTIONS", value: "actions", sortable: false, width: "200px" },
        ],
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        deleteResponse: false,
        updateResponse: false,
        isIntersecting: false,
        page: 1,
        loadingScroll: false,
        items: [],
        color: "",
      });

      const {
        checkAdmin,
        adminPassword,
        adminResponse,
        msgHeader,
        msgBody,
        msgIcon,
        deleteResponse,
        updateResponse,
        isIntersecting,
        page,
        loadingScroll,
        items,
        color,
      } = toRefs(editPermissionForm);

      watch(
        () => adminPassword.value,
        () => {
          if (adminPassword.value.length == 0 || adminPassword.value == "") {
            checkAdmin.value = "";
            adminResponse.value = false;
          }
        }
      );

      const removePermissionBtn = (id, item) => {
        deleteResponse.value = false;

        removePermission(id)
          .then(() => {
            getPermission().then(() => {
              editPermissionForm.isLoaded = false;
            });
          })
          .catch(() => {
            deleteResponse.value = true;
            msgHeader.value = "Error";
            msgBody.value =
              item.title +
              " permission " +
              " cannot be deleted. It is used in other component(s)";
            msgIcon.value = "mdi-close-circle";
          });
      };

      getPermission()
        .then(() => {
          items.value = [...getters_scroll_paginate.value];
          editPermissionForm.isLoaded = false;
        })
        .catch((e) => {
          if (e.response.status === 423) {
            msgBody.value = e.response.data.message;
            signOut().then(() => {
              vm.proxy.$router.replace({
                name: "Login",
              });
            });
          }
        });

      let { permissionName, basket, clickOnce, error_msgs, loading } =
        toRefs(permissionForm);

      const savePermissionBtn = () => {
        error_msgs.value = "";
        clickOnce.value = true;
        basket.value = {
          title: permissionName.value,
        };

        if (vm.proxy.$refs.permissionForm.validate()) {
          error_msgs.value = "";
          loading.value = true;
          savePermission(basket.value)
            .then(() => {
              getPermission().then(() => {
                editPermissionForm.isLoaded = false;
              });
              loading.value = false;
              vm.proxy.$refs.permissionForm.reset();
              clickOnce.value = false;
              updateResponse.value = true;
              msgHeader.value = "Success";
              msgBody.value =
                " Role and its permission(s) is successfully updated.";
              msgIcon.value = "mdi-check-outline";
              color.value = "success";
            })
            .catch((e) => {
              loading.value = false;

              if (e.response.status === 417) {
                error_msgs.value = permissionName.value + " already exists...";
              } else if (e.response.status === 403) {
                deleteResponse.value = true;
                msgBody.value = "This action is unauthorized";
              } else {
                error_msgs.value = "Try again later";
              }
              deleteResponse.value = true;
              msgHeader.value = "Error";
              msgIcon.value = "mdi-close-circle";
            });
        }
        clickOnce.value = false;
      };

      const intersected = (entries) => {
        isIntersecting.value = entries[0].isIntersecting;

        if (!isIntersecting.value) {
          return;
        }
        loadingScroll.value = true;

        if (
          getters_permission_meta.value.current_page >=
          getters_permission_meta.value.last_page
        ) {
          loadingScroll.value = false;
          return;
        }

        page.value++;
        getPermission(page.value).then(() => {
          loadingScroll.value = false;
          items.value = [
            ...new Set([...items.value, ...getters_scroll_paginate.value]),
          ];
        });
      };

      provide("color", color);

      return {
        ...toRefs(permissionForm),
        ...toRefs(editPermissionForm),
        savePermissionBtn,
        getters_scroll_paginate,
        removePermissionBtn,
        intersected,
        items,
      };
    },
  };
</script>
